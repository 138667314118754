import React, { useState, useEffect } from 'react'
import { graphql,  Link, navigate, useStaticQuery } from 'gatsby';
import Layout from "../../../../../components/NonUkResidents/HCP/layout";
import SEO from "../../../../../components/seo"
import { shouldShowDoccheckLoginPrompt } from '../../../../../controllers/nonUkResidents/hcp/doccheck';
import styles from "./doccheck.module.css"
import {
    getNonUkResidentsNonHcpGermanInfoDisclaimerPopupConfigsEfmody, isProduction,
} from "../../../../../util/configs"
import { ShowDisclaimer } from '../../../../../util/route-helper';
import UkResidentsLandingDisclaimer from "../../../../../components/prompts/disclaimers/ukResidents/user-selection-disclaimer"

class DocCheck extends React.Component {
    constructor(props){
        super(props);
        this.iframeUrl = isProduction ? (
            `https://login.doccheck.com/code/com/2000000017019/redirect_uri=https:{slash}{slash}eu.neurocrine.com{slash}NonUkResidents{slash}HCP{slash}information-for-german-prescribers-for-efmody{questionmark}login{equalsign}true`
        ) : (
            `https://login.doccheck.com/code/com/2000000017019/redirect_uri=https:{slash}{slash}staging-diurnal.netlify.app{slash}NonUkResidents{slash}HCP{slash}information-for-german-prescribers-for-efmody{questionmark}login{equalsign}true`
        );
        this.state = {
            showLogin: true,
            code: null,
            directLinkPopUpConfig: null
        }
    }

    async componentDidMount(){
        try {
            let showLogin = await shouldShowDoccheckLoginPrompt(this);
            this.setState({showLogin});
        }catch(e){}
        let okCallback = async () => await navigate("/");
        let closeCallback = async () => { };
        ShowDisclaimer("nonukresidents-hcp-german-prescribers", getNonUkResidentsNonHcpGermanInfoDisclaimerPopupConfigsEfmody(okCallback, closeCallback, true), this);
        localStorage.setItem("nonukresidents-hcp-german-prescribers", JSON.stringify({ disclaimerShown: true }));
        
    }

    goToGermanPrescribersPage = (e) => {
        e.preventDefault();

        let temporaryCode = localStorage.getItem("gatsby_doccheck_temporary_code");
        navigate(`/NonUkResidents/HCP/information-for-german-prescribers-for-efmody/?code=${temporaryCode}`);
        return;
    }

    render(){
        const { showLogin, code, directLinkPopUpConfig} = this.state;
        
        return (
            <Layout>
                {directLinkPopUpConfig && directLinkPopUpConfig.show && <UkResidentsLandingDisclaimer popupConfig={directLinkPopUpConfig} hide={() => this.setState({ directLinkPopUpConfig: null })} />}

                <SEO title={"Information For German Prescribers | DocCheck"} />
                <div className={styles.banner} style={{background: "url(https://images.ctfassets.net/bm339k2zg0t6/1CfQeYGs7yfoJbjMmFcTBE/9cdcbf960537ecf65caadde638a0dd5f/germany-flag-1398668_1280.jpg)"}}>
                    <div className={styles.overlay}></div>
                        <div className="container">
                           <div className="row">
                               <div className="col-12 col-sm-12 col-md-12">
                                
                               <h1 className="">Information For German Prescribers for Efmody</h1>
                               <ol className="custom_breadcrumb">
                               <li className="breadcrumb-item"><Link to="/NonUkResidents/HCP/home">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Information For German Prescribers for Efmody</li>
                                </ol>
                               </div>
                           
                               </div>
                        </div>
            </div>
                <div className="container">
                    <div className="row" style={{marginTop: 20}}>
                        
                        {showLogin ? (
                            <React.Fragment>
                                <div className="col-sm-12 col-12 col-md-6 col-lg-6">
                                    <p>
                                    Please note: The information on this website is aimed exclusively at medical experts and specialist media.
                                    <br/>
                                    The legal provisions of the Therapeutic Products Advertising Act allow certain specialist information to be passed on exclusively to specialist groups. This is why this page is only accessible with a DocCheck® login. We ask for your understanding.
                                    </p>
                                    <p>Bitte beachten Sie: Die Informationen auf dieser Website richten sich ausschließlich an medizinische Experten und Fachmedien.
                                    <br/>
                                    Die gesetzlichen Bestimmungen des Heilmittelwerbegesetzes sehen vor, dass bestimmte Fachinformationen ausschließlich an Fachkreise weitergegeben werden dürfen. Aus diesem Grund ist diese Seite nur mit einem DocCheck-Login zugänglich. Wir bitten um Ihr Verständnis.</p>
                                </div>
                                <div className="col-sm-12 col-12 col-md-6 col-lg-6">
                                <iframe
                                    align="left"
                                    frameBorder="0"
                                    scrolling="no"
                                    width="467"
                                    height="231"
                                    name="dc_login_iframe"
                                    id="dc_login_iframe"
                                    src={this.iframeUrl}
                                >
                                    <a href={this.iframeUrl} target="_blank">
                                    LOGIN
                                    </a>
                                </iframe>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="col-sm-12 col-12 col-md-6 col-lg-6">
                                <p>
                                    You are already logged in. Click on the button below to view information for German prescribers for Efmody.
                                </p>
                                <button className={"btn"} onClick={this.goToGermanPrescribersPage}>
                                    Information For German Prescribers for Efmody
                                </button>
                            </div>
                        )}

                    </div>
                </div>
            </Layout>
        )
    }
}

export default DocCheck;